<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";
import {paginationHelper} from "@/helpers/pagination-helper";
import {jsonUtil} from "@/helpers/json-util";
import {dateUtil} from "@/helpers/date-util";
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import allCountries from "@/helpers/all-countries";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";


/**
 * Wholesaler Management Component
 */
export default {
  components: {

    CustomCardSubtitle,
    Layout,
    PageHeader
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,

      limits: {
        warehouseName: 255,
        companyName: 255,
        companyAddress: 255,
        taxNumber: 10,
        website: 255,
        extraContent: 1000
      },

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: "",

        items: []
      },

      infoModal: false,
      extraContent: "",

      industries: [],

      modals: {
        accept: {
          visible: false,
          form: {
            wholesalerRegistration: null,
            carriers: [],
            oldWarehouse: null,
          }
        },

        edit: {
          visible: false,
          form: {
            wholesalerRegistration: null,
            carriers: []
          }
        }

      },

      filtering: {
        status: ""
      }

    };
  },

  validations() {
    return {
      modals: {
        edit: {
          form: {
            wholesalerRegistration: {
              warehouseName: {required, maxLength: maxLength(this.limits.warehouseName)},
              companyName: {required, maxLength: maxLength(this.limits.companyName)},
              industryId: {required},
              companyAddress: {required, maxLength: maxLength(this.limits.companyAddress)},
              taxNumber: {
                required,
                minLength: minLength(this.limits.taxNumber),
                maxLength: maxLength(this.limits.taxNumber)
              },
              contactPhoneDialCode: {required},
              contactPhoneNumber: {required},
              contactEmail: {required, email},
              website: {required, maxLength: maxLength(this.limits.website)},
              extraContent: {required, maxLength: maxLength(this.limits.extraContent)},
              marketId: {required}
            }
          }
        }
      }
    }
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getAllCountries() {
      return allCountries;
    },

    openWarehousePreview() {
      window.location = `/dashboard/admin/warehouse/show/${this.modals.accept.form.oldWarehouse.id}`
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('registration-forms.title'),
          active: true
        }
      ]
    },

    getStatusText(status) {
      if (status === 'ACCEPTED') {
        return "registration-forms.status.accepted"
      } else if (status === 'DECLINED') {
        return "registration-forms.status.declined"
      }

      return "registration-forms.status.waiting"
    },

    acceptRequest(id, accept) {
      this.submitted = true;
      const json = JSON.stringify({
        "id": id,
        "status": accept ? "ACCEPTED" : "DECLINED"
      });

      axios.post(`/wholesaler/registration/change-status`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t(accept ? 'registration-forms.accepted-success' : 'registration-forms.declined-success'), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    accept(id, accept) {
      this.submitted = true;
      const json = JSON.stringify({
        "id": id,
        "status": accept ? "ACCEPTED" : "DECLINED"
      });

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons.fire({
        title: this.$t('alert.title'),
        text: this.$t('alert.message'),
        icon: "warning",
        confirmButtonText: this.$t('alert.yes'),
        cancelButtonText: this.$t('alert.no'),
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          axios.post(`/wholesaler/registration/change-status`, json, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          }).then(() => {
            this.$bvToast.toast(this.$t(accept ? 'registration-forms.accepted-success' : 'registration-forms.declined-success'), {
              title: this.$t('message.success'),
              variant: 'success',
              solid: true
            });

            this.$refs.table.refresh()
          }).catch((error) => {
            errorCatcher.catchErrors(error)
            this.submitted = false;
          })
        }
      })
    },

    getFields() {
      return [
        {key: "warehouseName", label: "Wyświetlana nazwa"},
        {key: "companyName", label: this.$t('business.company-name')},
        {key: "companyAddress", label: this.$t('registration-forms.company-address')},
        {key: "taxNumber", label: this.$t('registration-forms.tax-number')},
        {key: "number", slot: true, label: this.$t('message.phone-number')},
        {key: "contactEmail", label: 'Email'},
        {key: "website", label: this.$t('registration-forms.website')},
        {key: "accepted", slot: true, label: 'Status'},
        {key: "industryId", slot: true, label: "Branża"},
        {key: "createdAt", label: "Data przesłania formularza", formatter: value => dateUtil.asDateTime(value)},
        {
          key: "acceptedAt", label: "Data utworzenia hurtowni", formatter: value => {
            if (!value) {
              return "-"
            }

            return dateUtil.asDateTime(value)
          }
        },
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadWholesalers() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/wholesaler/registration/pagination`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            page: page,
            size: this.table.perPage,
            filter: this.table.filter,
            ...this.filtering
          }
        });

        this.table.items = data.wholesalerRegistrationForms
        this.table.totalRows = data.count;
        this.table.rows = data.count

        await this.loadIndustries()
        if (this.mounted) {
          this.$refs.table.refresh()
        }

        return this.table.items
      } catch (error) {
        console.log(error)
        return []
      }
    },

    async loadIndustries() {
      try {
        if (this.table.items.length === 0) {
          return;
        }

        const ids = [];
        for (let wholesaler of this.table.items) {
          ids.push(wholesaler.industryId);
        }

        const json = JSON.stringify({
          "ids": ids
        });

        const {data} = await axios.post(`/industry/fetch`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.industries = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        console.log(error)
      }
    },

    getIndustry(id) { // TODO: make helper
      if (!this.industries) {
        return {}
      }

      const industry = this.industries.get(id)
      return industry ? industry : {};
    },

    /**
     * hide modal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.infoModal = false;
      this.extraContent = "";
    },

    showInfoModal(extraContent) {
      this.extraContent = extraContent;
      this.infoModal = true
    },

    deleteCarrier(warehouseCarrier) {
      const index = this.modals.accept.form.carriers.findIndex((element) => {
        return element === warehouseCarrier;
      });

      if (index !== -1) {
        this.modals.accept.form.carriers.splice(index, 1);
      }
    },

    addNewCarrier() {
      this.modals.accept.form.carriers.push({
        carrierId: "",
        allegroCarrierId: "",
        shippingCost: 0
      })
    },

    async openAcceptModal(wholesalerRegistration) {
      this.modals.accept.visible = true
      this.modals.accept.form.wholesalerRegistration = wholesalerRegistration
      this.modals.accept.form.carriers = jsonUtil.asArray(wholesalerRegistration.carriers)

      if (!this.modals.accept.form.carriers) {
        this.modals.accept.form.carriers = []
      }

      await this.loadOldWarehouse(wholesalerRegistration.taxNumber)
    },

    hideAcceptModal() {
      this.modals.accept.visible = false
      this.modals.accept.form.wholesalerRegistration = null
      this.modals.accept.form.carriers = []
      this.modals.accept.form.oldWarehouse = null
    },

    async loadOldWarehouse(taxNumber) {
      try {
        const {data} = await axios.get(`/warehouse/by-tax-number/${taxNumber}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.modals.accept.form.oldWarehouse = data
      } catch (error) {
        // ignored
      }
    },

    acceptModal() {
      this.submitted = true

      if (this.modals.accept.form.carriers) {
        for (const carrier of this.modals.accept.form.carriers) {
          if (!carrier.carrierId) {
            return;
          }

          if (!carrier.allegroCarrierId) {
            return
          }
        }
      }

      if (!this.modals.accept.form.carriers || this.modals.accept.form.carriers.length === 0) {
        Swal.fire("Błąd!", "Brak przewoźników!", "error")
        return
      }

      const json = JSON.stringify({
        "formId": this.modals.accept.form.wholesalerRegistration.id,
        "carriers": JSON.stringify(this.modals.accept.form.carriers)
      })

      axios.post(`/wholesaler/registration/edit-carriers`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.acceptRequest(this.modals.accept.form.wholesalerRegistration.id, true)
        this.hideAcceptModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    changeFilteringStatus(value) {
      this.filtering.status = value
      this.table.currentPage = 1

      this.$refs.table.refresh()
    },

    changeStatusText(status) {
      if (status === 'ACCEPTED') {
        return "registration-forms.status.accepted"
      } else if (status === 'DECLINED') {
        return "registration-forms.status.declined"
      } else if (status === 'AWAITING_CONSIDERATION') {
        return "registration-forms.status.waiting"
      }

      return status
    },

    openEditModal(wholesalerRegistration) {
      this.modals.edit.form.wholesalerRegistration = Object.assign({}, wholesalerRegistration)
      this.modals.edit.form.carriers = jsonUtil.asArray(this.modals.edit.form.wholesalerRegistration.carriers)
      if (!this.modals.edit.form.carriers) {
        this.modals.edit.form.carriers = []
      }

      this.modals.edit.visible = true
    },

    hideEditModal() {
      this.modals.edit.visible = false
      this.modals.edit.form.wholesalerRegistration = null
    },

    editWholesalerRegistration() {
      if (this.modals.edit.form.carriers) {
        const hasPermission = this.$store.getters['role/hasPermission']('WAREHOUSE_ALLEGRO_CARRIER')

        for (const carrier of this.modals.edit.form.carriers) {
          if (!carrier.carrierId) {
            return;
          }

          if (!carrier.allegroCarrierId && hasPermission) {
            return
          }
        }
      }

      if (!this.modals.edit.form.carriers || this.modals.edit.form.carriers.length === 0) {
        Swal.fire("Błąd!", "Brak przewoźników!", "error")
        return
      }

      this.submitted = true;
      const json = JSON.stringify({
        "form": {
          ...this.modals.edit.form.wholesalerRegistration,
          "carriers": JSON.stringify(this.modals.edit.form.carriers)
        }
      });

      axios.post(`/wholesaler/registration/update`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.hideEditModal()
        this.$bvToast.toast(this.$t("Formularz został zaktualizowany!"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    deleteEditCarrier(warehouseCarrier) {
      const index = this.modals.edit.form.carriers.findIndex((element) => {
        return element === warehouseCarrier;
      });

      if (index !== -1) {
        this.modals.edit.form.carriers.splice(index, 1);
      }
    },

    addEditNewCarrier() {
      this.modals.edit.form.carriers.push({
        carrierId: "",
        allegroCarrierId: "",
        shippingCost: 0
      })
    },

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('registration-forms.title')" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Filtrowanie"/>

              <div class="col-12 col-lg-2">
                <ecat-multiselect
                    label="Status"
                    @change="value => changeFilteringStatus(value)"
                    :custom-label="value => $t(changeStatusText(value))"
                    reset-option-name="Wszystkie statusy"
                    placeholder="Wszystkie statusy"
                    load-url="/wholesaler/registration/status"
                    :can-unselect="true"
                />
              </div>
            </div>

            <ecat-table
                ref="table"
                :table="table"
                :fields="getFields()"
                :items="loadWholesalers"
                :pagination-top="true"
            >
              <template v-slot:number="{ item }">
                <span> {{ item.contactPhoneDialCode }} {{ item.contactPhoneNumber }}</span>
              </template>

              <template v-slot:accepted="{ item }">
                          <span class="font-size-11 badge"
                                :class="{ 'badge-soft-success': item.status === 'ACCEPTED',
                                'badge-soft-danger': item.status === 'DECLINED',
                                'badge-soft-warning': item.status === 'AWAITING_CONSIDERATION' }"> {{
                              $t(getStatusText(item.status))
                            }}</span>
              </template>

              <template v-slot:action="{ item }">
                <div class="button-items">
                  <a class="mr-2 text-primary clickable-element" @click="openEditModal(item)"><i
                      class="mdi mdi-pencil font-size-18"/></a>

                  <a id="form-info" @click="showInfoModal(item.extraContent)"
                     class="clickable-element mr-2 text-primary"><i class="mdi mdi-information font-size-18"></i></a>
                  <b-tooltip target="form-info" placement="left">{{ $t('registration-forms.show-info') }}</b-tooltip>

                  <template v-if="item.status === 'AWAITING_CONSIDERATION'">
                    <a id="form-accept" @click="openAcceptModal(item)" class="clickable-element mr-2 text-success"><i
                        class="mdi mdi-check font-size-18"></i></a>
                    <b-tooltip target="form-accept" placement="left">{{ $t('registration-forms.accept') }}</b-tooltip>

                    <a id="form-decline" @click="accept(item.id, false)"
                       class="clickable-element text-danger font-size-18">✗</a>
                    <b-tooltip target="form-decline" placement="left">{{ $t('registration-forms.decline') }}</b-tooltip>
                  </template>
                </div>

              </template>

              <template v-slot:industryId="{ item }">
                <div :set="industry = getIndustry(item.industryId)">
                  <span>{{ industry.name }}</span>
                </div>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        :scrollable="true"
        v-model="infoModal"
        :title="$t('registration-forms.details')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>

        <pre style="font-family: inherit; font-size: 14px;"> {{ extraContent }}</pre>

        <div class="text-center">
          <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
        id="modal-2"
        size="xl"
        v-model="modals.accept.visible"
        v-if="modals.accept.form.wholesalerRegistration"
        :title="`Akceptowanie hurtowni: ${modals.accept.form.wholesalerRegistration.companyName}`"
        title-class="font-18"
        hide-footer
        @hide="hideAcceptModal"
        @esc="hideAcceptModal">
      <form>
        <div class="form-group" v-if="modals.accept.form.oldWarehouse && modals.accept.form.oldWarehouse.deleteReason">
          <div class="text-center">
            <h2 class="text-danger">Ta hurtownia z takim NIPEM była już zbanowana!</h2>
            <h3>Powód:</h3>
            <h5 class="text-danger">{{ modals.accept.form.oldWarehouse.deleteReason }}</h5>
            <b-button variant="primary" @click="openWarehousePreview">Podgląd hurtowni</b-button>
          </div>
        </div>

        <div class="form-group">
          <h5 class="text-center">Szczegóły o hurtowni:</h5>
          <pre style="font-family: inherit; font-size: 14px;"> {{
              modals.accept.form.wholesalerRegistration.extraContent
            }}</pre>
        </div>

        <div class="form-group">
          <h5 class="text-center">Ceny dostaw hurtowni:</h5>

          <div v-for="(warehouseCarrier, index) in modals.accept.form.carriers" :key="index" class="mb-2">
            <div class="row">
              <div class="col-4">
                <ecat-multiselect
                    placeholder="Wybierz przewoźnika"
                    @change="value => warehouseCarrier.carrierId = value"
                    :set-value="warehouseCarrier.carrierId"
                    fetch-one-url="/carrier"
                    load-url="/carrier/list/pagination"
                    query-url="/carrier/by-name"
                    save-id="id"
                    view-id="name"
                    param="name"
                    :class="{ 'is-invalid': submitted && !warehouseCarrier.carrierId }"
                />
                <div v-if="submitted && !warehouseCarrier.carrierId" class="invalid-feedback">
                  {{ $t('message.required') }}
                </div>
              </div>

              <div class="col-4">
                <ecat-multiselect
                    placeholder="Wybierz przewoźnika Allegro"
                    @change="value => warehouseCarrier.allegroCarrierId = value"
                    :set-value="warehouseCarrier.allegroCarrierId"
                    fetch-one-url="/allegro/carrier"
                    load-url="/allegro/carrier/list/pagination"
                    query-url="/allegro/carrier/list/by-name"
                    save-id="id"
                    view-id="name"
                    param="name"
                    :class="{ 'is-invalid': submitted && !warehouseCarrier.allegroCarrierId }"
                />
                <div v-if="submitted && !warehouseCarrier.allegroCarrierId" class="invalid-feedback">
                  {{ $t('message.required') }}
                </div>
              </div>

              <div class="col-3">
                <input v-model.number="warehouseCarrier.shippingCost" type="number" class="form-control"/>
              </div>

              <div class="col-1 py-1">
                <b-button variant="danger" class="btn-sm" @click="deleteCarrier(warehouseCarrier)">Usuń</b-button>
              </div>
            </div>
          </div>

          <div class="text-center py-3">
            <b-button variant="success" @click="addNewCarrier">Dodaj nową opcję</b-button>
          </div>
        </div>

        <div class="text-center">
          <b-button class="ml-1" variant="success" @click="acceptModal">Zaakceptuj</b-button>
          <b-button class="ml-1" variant="danger" @click="hideAcceptModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
        id="modal-3"
        :scrollable="true"
        size="xl"
        v-model="modals.edit.visible"
        v-if="modals.edit.form.wholesalerRegistration"
        title="Edycja formularza"
        title-class="font-18"
        hide-footer
        @hide="hideEditModal"
        @esc="hideEditModal">
      <form>

        <div class="form-group">
          <ecat-multiselect
              :set-value="modals.edit.form.wholesalerRegistration.industryId"
              @change="value => modals.edit.form.wholesalerRegistration.industryId = value"
              save-id="id"
              view-id="name"
              :label="$t('message.industry')"
              placeholder="Wybierz branże"
              fetch-one-url="/industry"
              load-url="/industry/list/pagination"
              query-url="/industry/by-name"
              list-name="industries"
              param="name"
              :class="{ 'is-invalid': submitted && $v.modals.edit.form.wholesalerRegistration.industryId.$error }"
          />

          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <ecat-multiselect
              :set-value="modals.edit.form.wholesalerRegistration.marketId"
              @change="value => modals.edit.form.wholesalerRegistration.marketId = value"
              save-id="id"
              :custom-label="value => $t(value.i18nTranslation)"
              label="Rynek"
              placeholder="Wybierz rynek"
              fetch-one-url="/market"
              load-url="/market/pagination"
              list-name="markets"
              :class="{ 'is-invalid': submitted && $v.modals.edit.form.wholesalerRegistration.marketId.$error }"
          />

          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.marketId.required"
               class="invalid-feedback">{{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label for="company-name">{{ $t('business.company-name') }}</label>
          <input id="company-name" v-model="modals.edit.form.wholesalerRegistration.companyName"
                 :class="{ 'is-invalid': submitted && $v.modals.edit.form.wholesalerRegistration.companyName.$error }"
                 class="form-control" :maxlength="this.limits.companyName" type="text"/>
          <p v-if="modals.edit.form.wholesalerRegistration.companyName" class="badge position-absolute"
             :class="{ 'badge-success': modals.edit.form.wholesalerRegistration.companyName.length !== this.limits.companyName, 'badge-danger': modals.edit.form.wholesalerRegistration.companyName.length === this.limits.companyName }">
            {{ modals.edit.form.wholesalerRegistration.companyName.length }} /
            {{ this.limits.companyName }}
          </p>

          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.companyName.required"
               class="invalid-feedback">{{ $t('message.required') }}
          </div>
          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.companyName.maxLength"
               class="invalid-feedback">
            {{ $t('wholesaler-management.register.company-name-too-long') }}
          </div>
        </div>

        <div class="form-group">
          <label for="visible-name">Wyświetlana nazwa</label>
          <input id="visible-name" v-model="modals.edit.form.wholesalerRegistration.warehouseName"
                 :class="{ 'is-invalid': submitted && $v.modals.edit.form.wholesalerRegistration.warehouseName.$error }"
                 class="form-control" :maxlength="this.limits.warehouseName" type="text"/>
          <p v-if="modals.edit.form.wholesalerRegistration.warehouseName" class="badge position-absolute"
             :class="{ 'badge-success': modals.edit.form.wholesalerRegistration.warehouseName.length !== this.limits.warehouseName, 'badge-danger': modals.edit.form.wholesalerRegistration.warehouseName.length === this.limits.warehouseName }">
            {{ modals.edit.form.wholesalerRegistration.warehouseName.length }} /
            {{ limits.warehouseName }}
          </p>

          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.warehouseName.required"
               class="invalid-feedback">{{ $t('message.required') }}
          </div>
          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.warehouseName.maxLength"
               class="invalid-feedback">
            {{ $t('wholesaler-management.register.company-name-too-long') }}
          </div>
        </div>

        <div class="form-group">
          <label for="company-address">{{ $t('wholesaler-management.register.company-info.address') }}</label>
          <input id="company-address" v-model="modals.edit.form.wholesalerRegistration.companyAddress"
                 :class="{ 'is-invalid': submitted && $v.modals.edit.form.wholesalerRegistration.companyAddress.$error }"
                 :maxlength="this.limits.companyAddress" class="form-control" type="text"/>
          <p v-if="modals.edit.form.wholesalerRegistration.companyAddress" class="badge position-absolute"
             :class="{ 'badge-success': modals.edit.form.wholesalerRegistration.companyAddress.length !== this.limits.companyAddress, 'badge-danger': modals.edit.form.wholesalerRegistration.companyAddress.length === this.limits.companyAddress }">
            {{ modals.edit.form.wholesalerRegistration.companyAddress.length }} /
            {{ this.limits.companyAddress }}
          </p>

          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.companyAddress.required"
               class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.companyAddress.maxLength"
               class="invalid-feedback">
            {{ $t('wholesaler-management.register.company-address-too-long') }}
          </div>
        </div>

        <div class="form-group">
          <label for="tax-number">{{ $t('business.tax') }}</label>
          <input id="tax-number" v-model="modals.edit.form.wholesalerRegistration.taxNumber"
                 :class="{ 'is-invalid': submitted && $v.modals.edit.form.wholesalerRegistration.taxNumber.$error }"
                 :maxlength="this.limits.taxNumber" class="form-control" type="text"/>
          <p v-if="modals.edit.form.wholesalerRegistration.taxNumber" class="badge position-absolute"
             :class="{ 'badge-success': modals.edit.form.wholesalerRegistration.taxNumber.length !== this.limits.taxNumber, 'badge-danger': modals.edit.form.wholesalerRegistration.taxNumber.length === this.limits.taxNumber }">
            {{ modals.edit.form.wholesalerRegistration.taxNumber.length }} /
            {{ this.limits.taxNumber }}
          </p>

          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.taxNumber.required"
               class="invalid-feedback">{{ $t('message.required') }}
          </div>
          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.taxNumber.minLength"
               class="invalid-feedback">
            {{ $t('wholesaler-management.register.tax-number-min-length') }}
          </div>
          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.taxNumber.maxLength"
               class="invalid-feedback">
            {{ $t('wholesaler-management.register.tax-number-min-length') }}
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label id="phone-country">{{ $t('message.country') }}</label>
              <select id="phone-country" v-model="modals.edit.form.wholesalerRegistration.contactPhoneDialCode"
                      :class="{ 'is-invalid': submitted && $v.modals.edit.form.wholesalerRegistration.contactPhoneDialCode.$error }"
                      class="custom-select">
                <option v-for="(item, index) in getAllCountries()" :key="index" :value="`${item.dialCode}`">
                  {{ item.name }} ({{ item.dialCode }})
                </option>
              </select>

              <div v-if="submitted && $v.modals.edit.form.wholesalerRegistration.contactPhoneDialCode.$error"
                   class="invalid-feedback">
                <span v-if="!$v.modals.edit.form.wholesalerRegistration.contactPhoneDialCode.required">{{
                    $t('message.required')
                  }}</span>
              </div>
            </div>

            <div class="col-6 mb-2">
              <label id="phone-number">{{ $t('message.phone-number') }}</label>
              <input
                  id="phone-number"
                  v-model="modals.edit.form.wholesalerRegistration.contactPhoneNumber"
                  :class="{ 'is-invalid': submitted && $v.modals.edit.form.wholesalerRegistration.contactPhoneNumber.$error }"
                  class="form-control"
                  type="text"/>
              <div v-if="submitted && $v.modals.edit.form.wholesalerRegistration.contactPhoneNumber.$error"
                   class="invalid-feedback">
                <span v-if="!$v.modals.edit.form.wholesalerRegistration.contactPhoneNumber.required">{{
                    $t('message.required')
                  }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="email">{{ $t('wholesaler-management.register.company-info.email') }}</label>
          <input id="email" v-model="modals.edit.form.wholesalerRegistration.contactEmail"
                 :class="{ 'is-invalid': submitted && $v.modals.edit.form.wholesalerRegistration.contactEmail.$error }"
                 class="form-control"
                 type="text"/>
          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.contactEmail.required"
               class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.contactEmail.email"
               class="invalid-feedback">
            {{ $t('message.email-error') }}
          </div>
        </div>

        <div class="form-group">
          <label for="website">{{ $t('wholesaler-management.register.company-info.website') }}</label>
          <input id="website" v-model="modals.edit.form.wholesalerRegistration.website"
                 :class="{ 'is-invalid': submitted && $v.modals.edit.form.wholesalerRegistration.website.$error }"
                 :maxlength="this.limits.website" class="form-control" type="text"/>
          <p v-if="modals.edit.form.wholesalerRegistration.website" class="badge position-absolute"
             :class="{ 'badge-success': modals.edit.form.wholesalerRegistration.website.length !== this.limits.website, 'badge-danger': modals.edit.form.wholesalerRegistration.website.length === this.limits.website }">
            {{ modals.edit.form.wholesalerRegistration.website.length }} /
            {{ this.limits.website }}
          </p>

          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.website.required"
               class="invalid-feedback">{{ $t('message.required') }}
          </div>
          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.website.maxLength"
               class="invalid-feedback">
            {{ $t('wholesaler-management.register.website-too-long') }}
          </div>
        </div>

        <div class="form-group">
          <label>{{ $t('wholesaler-management.register.details.specific-questions') }}</label>

          <label>{{ $t('wholesaler-management.register.details.xml-frequency') }}</label>
          <label>{{ $t('wholesaler-management.register.details.right-to-use-images') }}</label>
          <label>{{ $t('wholesaler-management.register.details.orders-through-website') }}</label>
          <label>{{ $t('wholesaler-management.register.details.form-of-communication') }}</label>
          <label>{{ $t('wholesaler-management.register.details.can-buy-one-piece') }}</label>
          <br>
          <label>{{ $t('wholesaler-management.register.details.transport-cost') }}</label>
          <label>{{ $t('wholesaler-management.register.details.shipment-from-directly') }}</label>
          <label>{{ $t('wholesaler-management.register.details.price-policy') }}</label>
          <label>{{ $t('wholesaler-management.register.details.complaints') }}</label>
          <label>{{ $t('wholesaler-management.register.details.refunds') }}</label>
          <label>{{ $t('wholesaler-management.register.details.paid-integration') }}</label>
          <label>{{ $t('wholesaler-management.register.details.image-watermark') }}</label>
          <label>{{ $t('wholesaler-management.register.details.shipping-date') }}</label>
          <label>{{ $t('wholesaler-management.register.details.xml-gab-information') }}</label>

          <label for="extraContent">{{ $t('wholesaler-management.register.details.reply') }}</label>
          <textarea id="extraContent" v-model="modals.edit.form.wholesalerRegistration.extraContent"
                    :class="{ 'is-invalid': submitted && $v.modals.edit.form.wholesalerRegistration.extraContent.$error }"
                    :maxlength="this.limits.extraContent" class="form-control" type="text"/>
          <p v-if="modals.edit.form.wholesalerRegistration.extraContent" class="badge position-absolute"
             :class="{ 'badge-success': modals.edit.form.wholesalerRegistration.extraContent.length !== this.limits.extraContent, 'badge-danger': modals.edit.form.wholesalerRegistration.extraContent.length === this.limits.extraContent }">
            {{ modals.edit.form.wholesalerRegistration.extraContent.length }} /
            {{ this.limits.extraContent }}
          </p>

          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.extraContent.required"
               class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
          <div v-if="submitted && !$v.modals.edit.form.wholesalerRegistration.extraContent.maxLength"
               class="invalid-feedback">
            {{ $t('wholesaler-management.register.extra-content-too-long') }}
          </div>
        </div>

        <div class="form-group">
          <h5 class="text-center">Ceny dostaw hurtowni</h5>

          <div v-for="(warehouseCarrier, index) in modals.edit.form.carriers" :key="index" class="mb-2">
            <div class="row">
              <div class="col-2" v-if="!$store.getters['role/hasPermission']('WAREHOUSE_ALLEGRO_CARRIER')"/>

              <div class="col-4">
                <ecat-multiselect
                    placeholder="Wybierz przewoźnika"
                    @change="value => warehouseCarrier.carrierId = value"
                    :set-value="warehouseCarrier.carrierId"
                    fetch-one-url="/carrier"
                    load-url="/carrier/list/pagination"
                    query-url="/carrier/by-name"
                    save-id="id"
                    view-id="name"
                    param="name"
                    :class="{ 'is-invalid': submitted && !warehouseCarrier.carrierId }"
                />
                <div v-if="submitted && !warehouseCarrier.carrierId" class="invalid-feedback">
                  {{ $t('message.required') }}
                </div>
              </div>

              <div class="col-4" v-if="$store.getters['role/hasPermission']('WAREHOUSE_ALLEGRO_CARRIER')">
                <ecat-multiselect
                    placeholder="Wybierz przewoźnika Allegro"
                    @change="value => warehouseCarrier.allegroCarrierId = value"
                    :set-value="warehouseCarrier.allegroCarrierId"
                    fetch-one-url="/allegro/carrier"
                    load-url="/allegro/carrier/list/pagination"
                    query-url="/allegro/carrier/list/by-name"
                    save-id="id"
                    view-id="name"
                    param="name"
                    :class="{ 'is-invalid': submitted && !warehouseCarrier.allegroCarrierId }"
                />
                <div v-if="submitted && !warehouseCarrier.allegroCarrierId" class="invalid-feedback">
                  {{ $t('message.required') }}
                </div>
              </div>

              <div class="col-3">
                <input v-model.number="warehouseCarrier.shippingCost" type="number" class="form-control"/>
              </div>

              <div class="col-1 py-1">
                <b-button variant="danger" class="btn-sm" @click="deleteEditCarrier(warehouseCarrier)">Usuń</b-button>
              </div>
            </div>
          </div>

          <div class="text-center py-3">
            <b-button variant="success" @click="addEditNewCarrier">Dodaj nową opcję</b-button>
          </div>
        </div>

        <div class="text-center">
          <b-button class="ml-1" variant="success" @click="editWholesalerRegistration">Zapisz</b-button>
          <b-button class="ml-1" variant="danger" @click="hideEditModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>